<!-- Testing comments, this will work too. -->
<template>
  <div>
    <!-- #region::Searcher -->
    <b-input-group class="input-group-merge">
      <b-input-group-prepend is-text>
        <feather-icon icon="SearchIcon" />
      </b-input-group-prepend>
      <b-form-input
        v-model="searchTerm"
        :placeholder="placeholder"
        type="text"
        @input="handleSearch()"
        @keydown.enter.prevent
        @keydown.enter.native="addKeyDownEvent ? handleSearch(0) : null"
      />
    </b-input-group>
    <!-- #endregion::Searcher -->
  </div>
</template>

<script>
import {
  BFormInput, BInputGroup, BInputGroupPrepend,
} from 'bootstrap-vue'

export default {
  components: {
    BFormInput,
    BInputGroup,
    BInputGroupPrepend,
  },
  props: {
    callback: {
      type: Function,
      required: true,
    },
    placeholder: {
      type: String,
      required: true,
    },
    waitingTime: {
      type: Number,
      default: 1000,
    },
    addKeyDownEvent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      searchTerm: '',
    }
  },
  methods: {
    // 01/09/2022 - TAG: Launch callback afeter waiting time based on search term
    handleSearch(waitingTime = this.waitingTime) {
      if (this.timeout) {
        clearTimeout(this.timeout)
      }

      this.timeout = setTimeout(() => {
        this.callback(this.searchTerm)
      }, waitingTime)
    },
    loadSearchTerm(searchTerm) {
      this.searchTerm = searchTerm
    },
  },
}
</script>
